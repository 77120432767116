import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="next-game"
export default class NextGameController extends Controller<HTMLElement> {
  static targets = ["event"];

  declare readonly hasEventTarget: boolean;
  declare readonly eventTargets: HTMLElement[];

  connect() {
    if (this.hasEventTarget) {
      const now = new Date();
      this.eventTargets.some((event) => {
        if (event.dataset.nextGameDatetime) {
          const datetime = new Date(event.dataset.nextGameDatetime);
          if (datetime > now) {
            event.classList.add("next-game");
            return true;
          }
        }
      });
    }
  }
}
